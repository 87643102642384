import Vue from 'vue'     // 引入VUE核心库
import App from './App.vue'   // 引入一个当前目录下的名字为App.vue的组件
import Mint from 'mint-ui'   //饿了吗 移动端 UI 组件库

//插件
import store from './store'
import router from './router/index'
import ElementUI from 'element-ui'; // 导入element-ui包
import 'element-ui/lib/theme-chalk/index.css'; // 导入element-ui相关样式
import 'mint-ui/lib/style.css'  // 导入mint-ui相关样式
import  axios  from  'axios'
import  VueAxios from  'vue-axios'
import VideoPlayer from 'vue-video-player'
//JS、css文件
import utils from "./utils/utils";
import newApi from './utils/newApi';
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

/**
 * Vue.use和Vue.prototype的区别
 * 1.针对Vue编写的插件用Vue.use导入
 * 2.不是针对Vue编写的插件用Vue.prototype导入
* */
Vue.use(ElementUI);
Vue.use(VueAxios,axios);   //vue-axios是按照vue插件的方式去写的。那么结合vue-axios，就可以去使用vue.use方法了
Vue.use(VideoPlayer);
Vue.use(Mint);
Vue.config.productionTip = false;  // 是否要在生产环境当中给予提示功能。
Vue.prototype.$EventBus = new Vue(); //初始化的EventBus是一个全局的事件总线
Vue.prototype.utils = utils;
Vue.prototype.newApi = newApi;

var loading;
//环境参数
if(process.env.NODE_ENV == "production"){
    store.state.devApiUrl = process.env.VUE_APP_BASE_API;
    // store.state.devApiUrl = process.env.VUE_APP_NATAPP_API;
    console.log("当前环境==>：" + process.env.NODE_ENV + "==>Api地址==>：" + store.state.devApiUrl );
}

Vue.prototype.openLoading = function(msg){
    loading = utils.loading(msg);
    return loading;
}

Vue.prototype.closeLoading = function(){
    if(typeof loading != "undefined"){
        loading.close();
    }
    loading = undefined;
}

//element成功提示
Vue.prototype.sus = function(content){
  this.$message({
    message: content,
    type: 'success'
  });
}

//element错误提示
Vue.prototype.err = function(content){
  this.$message.error(content);
}

//element消息提示
Vue.prototype.msg = function(content){
  this.$message({
    message: content,
    type: 'warning'
  });
}


//封装一个全局Ajax
Vue.prototype.devAjax = function (url,obj,callback) {
    var that = this;
    const params = new URLSearchParams();
    for (var sProp in obj) {
        params.append(sProp,obj[sProp]);
    }

    var allUrl = store.state.devApiUrl+url;
    axios.post(allUrl,params).then(({data}) => {
        if (data.isSuccess == 0) {
            that.err(data.msg);
        }else{
            if (typeof(callback) == "function") {
                callback(data);
            }
        }
    }).catch(function(resp){
        that.err(resp);
    });
}

new Vue({       // 生成一个VUE实例
  store,        //传递给vue实例
  router,       //在Vue实例中挂载创建的路由实例
  render: h => h(App),      // 渲染函数,将组件App渲染到挂载的元素上
}).$mount('#app')  //这个 #app 是挂载在 public 主页面上的，你打开index 就会看到 这个元素 id
