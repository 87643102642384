import axiosUtils from './axiosUtils.js'
import store from '../store/index'

const newApi = {
    data(data){
        const params = new URLSearchParams();
        for (const sProp in data) {
            params.append(sProp,data[sProp]);
        }
        return params;
    },

    /**
     * 获取微信分享文章
     * */
    getShareContent(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getShareContent", this.data(param));
    },
    /**
     * 获取微信分享签名
     * */
    getWechatShare(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getWechatShare", this.data(param));
    },
    /**
     * 微信网页授权获取code
     * */
    getCodeUrl(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getCodeUrl", this.data(param));
    },
    /**
     * 微信公众号支付
     * */
    wxPubPay(param) {
        return axiosUtils.post(store.state.devApiUrl + "/wxPubPay", this.data(param));
    },

    /**
     * 支付宝App支付
     * */
    aliPay(param) {
        return axiosUtils.post(store.state.devApiUrl + "/aliPay", this.data(param));
    },
    /**
     * 查询微信或支付宝订单 是否完成
     * */
    queryOrderInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/queryOrderInfo", this.data(param));
    },

    /**
     * 用户注册
     * @param param
     */
    userRegister(param){
        return axiosUtils.post(store.state.devApiUrl + "/userRegister", this.data(param));
    },
    /**
     * 更新用户登录时间
     * @param param
     */
    updateLoginTime(param) {
        return axiosUtils.post(store.state.devApiUrl + "/updateLoginTime", this.data(param));
    },
    /**
     * 用户进行实名认证
     * */
    userReadNameAuth(param) {
        return axiosUtils.post(store.state.devApiUrl + "/userReadNameAuth", this.data(param));
    },
    /**
     * 获取用户信息
     * @param param
     */
    getUserInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getUserInfo", this.data(param));
    },
    /**
     * 发送短信验证码
     * @param param
     */
    sendSms(param){
        return axiosUtils.post(store.state.devApiUrl + "/sendSms", this.data(param));
    },
    /**
     * 短信验证码登录
     * @param param
     */
    getLoginCode(param){
        return axiosUtils.post(store.state.devApiUrl + "/getLoginCode", this.data(param));
    },
    /**
     * 获取ROOM详情
     * @param param
     */
    getRoomInfo(param){
        return axiosUtils.post(store.state.devApiUrl + "/getRoomInfo", this.data(param));
    },
    /**
     * 使用口令加入room
     * */
    passwordJoinRoom(param){
        return axiosUtils.post(store.state.devApiUrl + "/passwordJoinRoom", this.data(param));
    },
    /**
     * 账号密码登录
     * @param param
     */
    getLoginAccount(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getLoginAccount", this.data(param));
    },
    /**
     * 获取微信code
     * @param param
     */
    getMLoginCodeUrl(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMLoginCodeUrl", this.data(param));
    },
    /**
     * 获取微信openid
     * @param param
     */
    getMWxOpenId(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMWxOpenId", this.data(param));
    },
    /**
     * 获取用户多账户信息(带UUID)
     * @param param
     */
    getLoginCodeList(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getLoginCodeList", this.data(param));
    },
    /**
     * OSS上传
     * @param param
     */
    getFileInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getFileInfo", this.data(param));
    },
    /**
     * OSS鉴权
     * @param param
     */
    getStsAuth(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getStsAuth", this.data(param));
    },
    /**
     * OSS回调
     * @param param
     */
    ossNotify(param) {
        return axiosUtils.post(store.state.devApiUrl + "/fileUploadCallback", this.data(param));
    },
    /**
     * 获取用户信息
     * @param param
     */
    getMUserInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getMUserInfo", this.data(param));
    },
    /**
     * 扫码上传的文件
     * @param param
     */
    addCodeFile(param) {
        return axiosUtils.post(store.state.devApiUrl + "/addCodeFile", this.data(param));
    },
    /**
     * 删除扫码上传的文件
     * @param param
     */
    delCodeFiles(param) {
        return axiosUtils.post(store.state.devApiUrl + "/delCodeFiles", this.data(param));
    },
}

export default newApi
