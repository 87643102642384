import Vue from "vue";
import VueRouter from 'vue-router' //导入路由对象

//1.调用Vue.use(VueRouter)
Vue.use(VueRouter);   //在模块化工程中使用它（因为是一个插件，所以可以通过Vue.use()来安装路由功能）

//2.创建VueRouter对象
/**
*  vue项目实现路由按需加载(路由懒加载)的3中方式
*  注：如果没有应用懒加载，运用webpack打包后文件将会异常的大，造成进入首页后需要加载的内容过多，时间过长。而运用懒加载则可以将页面进行划分，需要到时候加载页面。
*  1.vue异步组件
*  2.es提案的import()
*  3.webpack的require.ensure()
*  这里我们使用第3种 路由也是正常的写法  这种是官方推荐的写的 按模块划分懒加载
*  实例：r就是resolve
*  [ path: '/home', name: home, component: r => require.ensure([], () => r(require(@/components/home')), 'demo') ]
* */
const router = new VueRouter({
    mode:"history",
    routes:[
        {
            path:'/',   //聚合扫码页面
            component: r => require.ensure([], () => r(require('../pages/index/scanPayCode.vue'))),
            meta:{
                title:"聚合扫码页面"
            }
        },
        {
            path:'/weChat',   //微信支付
            component: r => require.ensure([], () => r(require('@/components/pay/weChat.vue'))),
            meta:{
                title:"微信支付"
            }
        },
        {
            path:'/articleInfo',   //文章详情
            component: r => require.ensure([], () => r(require('../pages/weShare/articleInfo.vue'))),
            props: route => ({
                type: route.query.type,
                id: route.query.id,
            }),
            meta:{
                title:"文章详情"
            }
        },
        {
            path:'/DescribeFaceVerify',   //人脸认证结果页面
            component: r => require.ensure([], () => r(require('../pages/index/DescribeFaceVerify'))),
            meta:{
                title:"人脸认证结果页面"
            }
        },
        {
            path:'/face',   //人脸认证页面
            component: r => require.ensure([], () => r(require('../pages/index/face'))),
            meta:{
                title:"人脸认证"
            }
        },
        {
            path:'/reg',   //注册
            name:'reg',
            component: r => require.ensure([], () => r(require('../components/reg/reg.vue'))),
            meta:{
                title:"注册"
            }
        },
        {
            path:'/joinRoom',   //加入room
            component: r => require.ensure([], () => r(require('../pages/room/joinRoom'))),
            meta:{
                title:"加入room"
            }
        },
        {
            path:'/uploadImages',   //图片上传
            component: r => require.ensure([], () => r(require('../pages/upload/uploadImages.vue'))),
            meta:{
                title:"图片上传"
            }
        },
        {
            path:'/uploadVideo',   //视频上传
            component: r => require.ensure([], () => r(require('../pages/upload/uploadVideo.vue'))),
            meta:{
                title:"视频上传"
            }
        },
        {
            path:'/uploadFile',   //附件上传
            component: r => require.ensure([], () => r(require('../pages/upload/uploadFile.vue'))),
            meta:{
                title:"视频上传"
            }
        },
    ]

    /**
     * 这段是路由测试
    * */
    // routes:[
    //     {
    //         path:'/',  //默认首页
    //         component: r => require.ensure([], () => r(require('../pages/test/home'))),
    //         meta:{
    //             title:"百工宝 | 规划建设项目一站式协作平台"
    //         }
    //     },
    //     {
    //         path:'/about/:id',
    //         component: r => require.ensure([], () => r(require('../pages/test/about'))),
    //         meta:{
    //             title:"关于我们"
    //         },
    //     },
    //     {
    //         path:'/add',
    //         component: r => require.ensure([], () => r(require('../pages/test/add'))),
    //         props: route => ({
    //             class_id: route.query.class_id,
    //             room_id: route.query.room_id,
    //         }),
    //         meta:{
    //             title:"添加页面"
    //         },
    //     }
    // ]
})

/**
 * 导航守卫的使用
 * 可以利用beforeEach来完成标题的修改
 * 1.首先，我们可以在钩子当中定义一些标题，可以利用mate来定义
 * 2.其次，利用导航守卫修改我们的标题
 * 导航钩子的三个参数解析：
 * 1.to：即将要进入的目标的路由对象
 * 2.from：当前导航即要离开的路由对象
 * 3.next：调用该方法后，才能进入下一个钩子
 * 如果是后置钩子，也就是afterEach，不需要主动调用next(）函数。
 * 下面我们使用的导航守卫，被称之为全局守卫
 * */
router.beforeEach((to,from,next) =>{
    window.document.title = to.matched[0].meta.title
    next()
})

//3.将router对象传入到Vue实例 (需要这段代码作为输出，否则报错)
export default router
