<template>
  <div id="app">
<!--    直接访问router设置的根目录-->
     <router-view></router-view>

<!--      图片进度条-->
      <div class="shadow fade" style="position:fixed;top:110px;width:398px;background:#FFF;left:calc( 50% - 200px );padding:10px 0;border-radius:4px;z-index:2100;" v-if="upload_info.length > 0">
          <div v-for="(item,index) in upload_info" :key="index" style="padding:10px 50px; overflow:auto;" class="fade" >
              <div >
                  <div style="text-align:center;margin:0 0 5px 0;font-size:20px;">{{item.file_name}}上传中</div>
                  <el-progress :text-inside="true" :stroke-width="26" :percentage="item.percent"></el-progress>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'App',
    computed:{
        //vuex定义的图片数组 进度条用
        upload_info() {
            return this.$store.state.upload_info
        },
    },
  mounted(){
    // console.log(this.$store.state.is_login);
    // console.log(this.$router);
      //项目运行首先检查token是否过期
      if (window.localStorage.getItem("token") != null && window.localStorage.getItem("token") !== "" && window.localStorage.getItem("token") !== undefined) {
          this.$store.commit("get_user_info");
      }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
