import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/base.css';
import axios from 'axios';
import Vue from 'vue';
import utils from "@/utils/utils";

// 创建 axios 实例
const instance = axios.create({
    timeout: 20000,
    withCredentials: false, // 不要在修改了，要不然 websocket 一直会进行重新连接
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Token-Type': 2
    },
});

// 请求拦截器
instance.interceptors.request.use(config => {
    let token = localStorage.token;

    if (token) {
        config.headers.Authorization = "Bearer " + token;
    }

    // 判断是否为特定的接口请求
    let apiName = utils.subUrlStr(config.url, 3);
    let interfaceName = utils.subUrlStr(window.location.href, 3);
    if (apiName === 'getUserInfo' && interfaceName !== 'joinRoom') {
        // 直接返回一个拒绝的 Promise，不发送请求
        return Promise.reject();
    }

    return config;
}, error => {
    // 请求失败拦截
    return Promise.reject(error);
});

// 响应拦截器
instance.interceptors.response.use(response => {
    if (response.data.isSuccess === 0) {
        Vue.prototype.msg(response.data.data);
    }
    setTimeout(() => { Vue.prototype.closeLoading() }, 500);
    return response.data;
}, error => {
    setTimeout(() => { Vue.prototype.closeLoading() }, 500);

    // 提取错误状态码
    const status = error.response ? error.response.status : 0;

    // 判断是否请求超时
    if (error.code === 'ECONNABORTED') {
        // 特定环境的超时处理
        Vue.prototype.msg("请求超时，请稍后再试");
    } else if (error.response) {
        // 处理响应错误
        if (status === 401) {
            Vue.prototype.msg("账号信息已失效,请重新登陆");
        } else if (status === 500) {
            Vue.prototype.msg('服务器繁忙，请稍后再试');
        } else if (status === 404) {
            Vue.prototype.msg('接口不存在');
        } else {
            Vue.prototype.msg("未知错误");
            console.error('Error', error);
        }
    } else {
        // 处理其他错误
        Vue.prototype.msg("未知错误");
        console.error('Error', error);
    }

    // 返回一个新的 Promise 实例，以便代码继续往下执行
    return Promise.reject(error);
});

export default instance;
